import haladi_01 from '../images/photos/suvidhi_rishabh/haladi_01.jpg'
import haladi_02 from '../images/photos/suvidhi_rishabh/haladi_02.jpg'
import haladi_03 from '../images/photos/suvidhi_rishabh/haladi_03.jpg'
import haladi_04 from '../images/photos/suvidhi_rishabh/haladi_04.jpg'
import haladi_05 from '../images/photos/suvidhi_rishabh/haladi_05.jpg'
import haladi_06 from '../images/photos/suvidhi_rishabh/haladi_06.jpg'
import haladi_07 from '../images/photos/suvidhi_rishabh/haladi_07.jpg'
import haladi_08 from '../images/photos/suvidhi_rishabh/haladi_08.jpg'
import haladi_09 from '../images/photos/suvidhi_rishabh/haladi_09.jpg'
import haladi_10 from '../images/photos/suvidhi_rishabh/haladi_10.jpg'
 import haladi_11 from '../images/photos/suvidhi_rishabh/haladi_11.jpg'

import reception_01 from '../images/photos/suvidhi_rishabh/reception_01.jpg'
import reception_02 from '../images/photos/suvidhi_rishabh/reception_02.jpg'
import reception_03 from '../images/photos/suvidhi_rishabh/reception_03.jpg'
import reception_04 from '../images/photos/suvidhi_rishabh/reception_04.jpg'
import reception_05 from '../images/photos/suvidhi_rishabh/reception_05.jpg'
import reception_06 from '../images/photos/suvidhi_rishabh/reception_06.jpg'
import reception_07 from '../images/photos/suvidhi_rishabh/reception_07.jpg'
import reception_08 from '../images/photos/suvidhi_rishabh/reception_08.jpg'


import wedding_01 from '../images/photos/suvidhi_rishabh/wedding-01.jpg'
import wedding_02 from '../images/photos/suvidhi_rishabh/wedding-02.jpg'
import wedding_03 from '../images/photos/suvidhi_rishabh/wedding-03.jpg'
import wedding_04 from '../images/photos/suvidhi_rishabh/wedding-04.jpg'
import wedding_05 from '../images/photos/suvidhi_rishabh/wedding-05.jpg'
import wedding_06 from '../images/photos/suvidhi_rishabh/wedding-06.jpg'
import wedding_07 from '../images/photos/suvidhi_rishabh/wedding-07.jpg'
import wedding_08 from '../images/photos/suvidhi_rishabh/wedding-08.jpg'
import wedding_09 from '../images/photos/suvidhi_rishabh/wedding-09.jpg'
import wedding_10 from '../images/photos/suvidhi_rishabh/wedding-10.jpg'
import wedding_11 from '../images/photos/suvidhi_rishabh/wedding-11.jpg'
import wedding_12 from '../images/photos/suvidhi_rishabh/wedding-12.jpg'
import wedding_13 from '../images/photos/suvidhi_rishabh/wedding-13.jpg'
import wedding_14 from '../images/photos/suvidhi_rishabh/wedding-14.jpg'
import wedding_15 from '../images/photos/suvidhi_rishabh/wedding-15.jpg'
import wedding_16 from '../images/photos/suvidhi_rishabh/wedding-16.jpg'
import wedding_17 from '../images/photos/suvidhi_rishabh/wedding-17.jpg'
import wedding_18 from '../images/photos/suvidhi_rishabh/wedding-18.jpg'

import rn_01 from '../images/photos/rohan_neelam/rn-01.jpg'
import rn_02 from '../images/photos/rohan_neelam/rn-02.jpg'
import rn_03 from '../images/photos/rohan_neelam/rn-03.jpg'
import rn_04 from '../images/photos/rohan_neelam/rn-04.jpg'
import rn_05 from '../images/photos/rohan_neelam/rn-05.jpg'
import rn_06 from '../images/photos/rohan_neelam/rn-06.jpg'
import rn_07 from '../images/photos/rohan_neelam/rn-07.jpg'
import rn_08 from '../images/photos/rohan_neelam/rn-08.jpg'
import rn_09 from '../images/photos/rohan_neelam/rn-09.jpg'
import rn_10 from '../images/photos/rohan_neelam/rn-10.jpg'
import rn_11 from '../images/photos/rohan_neelam/rn-11.jpg'
import rn_12 from '../images/photos/rohan_neelam/rn-12.jpg'
import rn_13 from '../images/photos/rohan_neelam/rn-13.jpg'
import rn_14 from '../images/photos/rohan_neelam/rn-14.jpg'
import rn_15 from '../images/photos/rohan_neelam/rn-15.jpg'
import rn_16 from '../images/photos/rohan_neelam/rn-16.jpg'
import rn_17 from '../images/photos/rohan_neelam/rn-17.jpg'
import rn_18 from '../images/photos/rohan_neelam/rn-18.jpg'
import rn_19 from '../images/photos/rohan_neelam/rn-19.jpg'
import rn_20 from '../images/photos/rohan_neelam/rn-20.jpg'
import rn_21 from '../images/photos/rohan_neelam/rn-21.jpg'
import rn_22 from '../images/photos/rohan_neelam/rn-22.jpg'
import rn_23 from '../images/photos/rohan_neelam/rn-23.jpg'
import rn_24 from '../images/photos/rohan_neelam/rn-24.jpg'
import rn_25 from '../images/photos/rohan_neelam/rn-25.jpg'
import rn_26 from '../images/photos/rohan_neelam/rn-26.jpg'
import rn_27 from '../images/photos/rohan_neelam/rn-27.jpg'
import rn_28 from '../images/photos/rohan_neelam/rn-28.jpg'
import rn_29 from '../images/photos/rohan_neelam/rn-29.jpg'
import rn_30 from '../images/photos/rohan_neelam/rn-30.jpg'
import rn_31 from '../images/photos/rohan_neelam/rn-31.jpg'
import rn_32 from '../images/photos/rohan_neelam/rn-32.jpg'
import rn_33 from '../images/photos/rohan_neelam/rn-33.jpg'

import rn_35 from '../images/photos/rohan_neelam/rn-35.jpg'
import rn_36 from '../images/photos/rohan_neelam/rn-36.jpg'
import rn_37 from '../images/photos/rohan_neelam/rn-37.jpg'
import rn_38 from '../images/photos/rohan_neelam/rn-38.jpg'
import rn_39 from '../images/photos/rohan_neelam/rn-39.jpg'
import rn_40 from '../images/photos/rohan_neelam/rn-40.jpg'
import rn_41 from '../images/photos/rohan_neelam/rn-41.jpg'


import ma_411 from '../images/photos/meghna_arun/BZ9A7166.jpg'
import ma_42 from '../images/photos/meghna_arun/BZ9A7169.jpg'
import ma_43 from '../images/photos/meghna_arun/BZ9A7172.jpg'
import ma_44 from '../images/photos/meghna_arun/BZ9A7181.jpg'
import ma_45 from '../images/photos/meghna_arun/BZ9A7196.jpg'
import ma_46 from '../images/photos/meghna_arun/BZ9A7203.jpg'
import ma_47 from '../images/photos/meghna_arun/BZ9A7231.jpg'
import ma_48 from '../images/photos/meghna_arun/BZ9A7273.jpg'
import ma_49 from '../images/photos/meghna_arun/BZ9A7276.jpg'
import ma_50 from '../images/photos/meghna_arun/BZ9A7325.jpg'
import ma_51 from '../images/photos/meghna_arun/BZ9A7351.jpg'
import ma_52 from '../images/photos/meghna_arun/BZ9A7356.jpg'
import ma_53 from '../images/photos/meghna_arun/BZ9A7702.jpg'
import ma_54 from '../images/photos/meghna_arun/BZ9A7865.jpg'
import ma_55 from '../images/photos/meghna_arun/BZ9A7882.jpg'
import ma_56 from '../images/photos/meghna_arun/BZ9A7907.jpg'
import ma_57 from '../images/photos/meghna_arun/LEN00093.jpg'
import ma_58 from '../images/photos/meghna_arun/LEN00279.jpg'
import ma_59 from '../images/photos/meghna_arun/LEN00605.jpg'
import ma_60 from '../images/photos/meghna_arun/LEN00686.jpg'
import ma_61 from '../images/photos/meghna_arun/LEN04477.jpg'
import ma_62 from '../images/photos/meghna_arun/LEN04922.jpg'
import ma_63 from '../images/photos/meghna_arun/LEN05016.jpg'
import ma_64 from '../images/photos/meghna_arun/LEN05173.jpg'
import ma_65 from '../images/photos/meghna_arun/LEN05180.jpg'



import shalu_reza_01 from '../images/photos/shalu_reza/IMG-1.jpg'
import shalu_reza_02 from '../images/photos/shalu_reza/IMG-4.jpg'
import shalu_reza_03 from '../images/photos/shalu_reza/IMG-11.jpg'
import shalu_reza_04 from '../images/photos/shalu_reza/IMG-21.jpg'
import shalu_reza_05 from '../images/photos/shalu_reza/IMG-25.jpg'
import shalu_reza_06 from '../images/photos/shalu_reza/IMG-29.jpg'
import shalu_reza_07 from '../images/photos/shalu_reza/IMG-36.jpg'
import shalu_reza_08 from '../images/photos/shalu_reza/IMG-40.jpg'
import shalu_reza_09 from '../images/photos/shalu_reza/IMG-53.jpg'
import shalu_reza_10 from '../images/photos/shalu_reza/IMG-54.jpg'
import shalu_reza_11 from '../images/photos/shalu_reza/IMG-57.jpg'
import shalu_reza_12 from '../images/photos/shalu_reza/IMG-61.jpg'
import shalu_reza_13 from '../images/photos/shalu_reza/IMG-64.jpg'
import shalu_reza_14 from '../images/photos/shalu_reza/IMG-68.jpg'
import shalu_reza_15 from '../images/photos/shalu_reza/IMG-69.jpg'
import shalu_reza_16 from '../images/photos/shalu_reza/IMG-72.jpg'
import shalu_reza_17 from '../images/photos/shalu_reza/IMG-76.jpg'
import shalu_reza_18 from '../images/photos/shalu_reza/IMG-90.jpg'
import shalu_reza_19 from '../images/photos/shalu_reza/IMG-94.jpg'
import shalu_reza_20 from '../images/photos/shalu_reza/IMG-95.jpg'
import shalu_reza_21 from '../images/photos/shalu_reza/IMG-97.jpg'
import shalu_reza_22 from '../images/photos/shalu_reza/IMG-98.jpg'
import shalu_reza_23 from '../images/photos/shalu_reza/IMG-103.jpg'
import shalu_reza_24 from '../images/photos/shalu_reza/IMG-105.jpg'
import shalu_reza_25 from '../images/photos/shalu_reza/IMG-112.jpg'
import shalu_reza_26 from '../images/photos/shalu_reza/IMG-139.jpg'
import shalu_reza_27 from '../images/photos/shalu_reza/IMG-192.jpg'
import shalu_reza_28 from '../images/photos/shalu_reza/IMG-199.jpg'
import shalu_reza_29 from '../images/photos/shalu_reza/IMG-207.jpg'
import shalu_reza_30 from '../images/photos/shalu_reza/IMG-215.jpg'
import shalu_reza_31 from '../images/photos/shalu_reza/IMG-220.jpg'
import shalu_reza_32 from '../images/photos/shalu_reza/IMG-264.jpg'
import shalu_reza_33 from '../images/photos/shalu_reza/IMG-351.jpg'


import jenifer_nikcet_01 from '../images/photos/jenifer_nikcet/PK404016.jpg'
import jenifer_nikcet_02 from '../images/photos/jenifer_nikcet/PK404021.jpg'
import jenifer_nikcet_03 from '../images/photos/jenifer_nikcet/PK404030.jpg'
import jenifer_nikcet_04 from '../images/photos/jenifer_nikcet/PK404040.jpg'
import jenifer_nikcet_05 from '../images/photos/jenifer_nikcet/PK404044.jpg'
import jenifer_nikcet_06 from '../images/photos/jenifer_nikcet/PK404067.jpg'
import jenifer_nikcet_07 from '../images/photos/jenifer_nikcet/PK404103.jpg'
import jenifer_nikcet_08 from '../images/photos/jenifer_nikcet/PK404153.jpg'
import jenifer_nikcet_09 from '../images/photos/jenifer_nikcet/PK404213.jpg'
import jenifer_nikcet_10 from '../images/photos/jenifer_nikcet/PK404301.jpg'
import jenifer_nikcet_11 from '../images/photos/jenifer_nikcet/PK404326.jpg'
import jenifer_nikcet_12 from '../images/photos/jenifer_nikcet/PK404338.jpg'
import jenifer_nikcet_13 from '../images/photos/jenifer_nikcet/PK404365.jpg'
import jenifer_nikcet_14 from '../images/photos/jenifer_nikcet/PK404371.jpg'
import jenifer_nikcet_15 from '../images/photos/jenifer_nikcet/PK404436.jpg'
import jenifer_nikcet_16 from '../images/photos/jenifer_nikcet/PK404458.jpg'
import jenifer_nikcet_17 from '../images/photos/jenifer_nikcet/PP400991.jpg'
import jenifer_nikcet_18 from '../images/photos/jenifer_nikcet/PPP06296.jpg'
import jenifer_nikcet_19 from '../images/photos/jenifer_nikcet/PPP06345.jpg'
import jenifer_nikcet_20 from '../images/photos/jenifer_nikcet/PPP06365.jpg'



import abhash_shruti_01 from '../images/photos/abhash_shruti/C2.jpg'
import abhash_shruti_02 from '../images/photos/abhash_shruti/C3.jpg'
import abhash_shruti_03 from '../images/photos/abhash_shruti/C13.jpg'
import abhash_shruti_04 from '../images/photos/abhash_shruti/C19.jpg'
import abhash_shruti_05 from '../images/photos/abhash_shruti/H-696.jpg'
import abhash_shruti_06 from '../images/photos/abhash_shruti/H-857.jpg'
import abhash_shruti_07 from '../images/photos/abhash_shruti/H-942.jpg'
import abhash_shruti_08 from '../images/photos/abhash_shruti/H-976.jpg'
import abhash_shruti_09 from '../images/photos/abhash_shruti/R27.jpg'
import abhash_shruti_10 from '../images/photos/abhash_shruti/W3.jpg'
import abhash_shruti_11 from '../images/photos/abhash_shruti/W5.jpg'
import abhash_shruti_12 from '../images/photos/abhash_shruti/W9.jpg'
import abhash_shruti_13 from '../images/photos/abhash_shruti/W45.jpg'
import abhash_shruti_14 from '../images/photos/abhash_shruti/W151.jpg'
import abhash_shruti_15 from '../images/photos/abhash_shruti/W189.jpg'
import abhash_shruti_16 from '../images/photos/abhash_shruti/W237.jpg'
import abhash_shruti_17 from '../images/photos/abhash_shruti/W239.jpg'
import abhash_shruti_18 from '../images/photos/abhash_shruti/W343.jpg'
import abhash_shruti_19 from '../images/photos/abhash_shruti/W384.jpg'
import abhash_shruti_20 from '../images/photos/abhash_shruti/W401.jpg'
import abhash_shruti_21 from '../images/photos/abhash_shruti/W606.jpg'
import abhash_shruti_22 from '../images/photos/abhash_shruti/W614.jpg'
import abhash_shruti_23 from '../images/photos/abhash_shruti/W658.jpg'
import abhash_shruti_24 from '../images/photos/abhash_shruti/W726.jpg'
import abhash_shruti_25 from '../images/photos/abhash_shruti/W733.jpg'
import abhash_shruti_26 from '../images/photos/abhash_shruti/W746.jpg'
import abhash_shruti_27 from '../images/photos/abhash_shruti/W770.jpg'
import abhash_shruti_28 from '../images/photos/abhash_shruti/W1048.jpg'


import laya_shivank_01 from '../images/photos/laya_shivank/laya_shivank_01.jpg'
import laya_shivank_02 from '../images/photos/laya_shivank/laya_shivank_02.jpg'
import laya_shivank_03 from '../images/photos/laya_shivank/laya_shivank_03.jpg'
import laya_shivank_04 from '../images/photos/laya_shivank/laya_shivank_04.jpg'
import laya_shivank_05 from '../images/photos/laya_shivank/laya_shivank_05.jpg'
import laya_shivank_06 from '../images/photos/laya_shivank/laya_shivank_06.jpg'
import laya_shivank_07 from '../images/photos/laya_shivank/laya_shivank_07.jpg'
import laya_shivank_08 from '../images/photos/laya_shivank/laya_shivank_08.jpg'
import laya_shivank_09 from '../images/photos/laya_shivank/laya_shivank_09.jpg'
import laya_shivank_10 from '../images/photos/laya_shivank/laya_shivank_10.jpg'
import laya_shivank_11 from '../images/photos/laya_shivank/laya_shivank_11.jpg'
import laya_shivank_12 from '../images/photos/laya_shivank/laya_shivank_12.jpg'
import laya_shivank_13 from '../images/photos/laya_shivank/laya_shivank_13.jpg'
import laya_shivank_14 from '../images/photos/laya_shivank/laya_shivank_14.jpg'
import laya_shivank_15 from '../images/photos/laya_shivank/laya_shivank_15.jpg'
import laya_shivank_16 from '../images/photos/laya_shivank/laya_shivank_16.jpg'
import laya_shivank_17 from '../images/photos/laya_shivank/laya_shivank_17.jpg'
import laya_shivank_18 from '../images/photos/laya_shivank/laya_shivank_18.jpg'


import milouni_kunal_01 from '../images/photos/milouni_kunal/C49.jpg'
import milouni_kunal_02 from '../images/photos/milouni_kunal/C185.jpg'
import milouni_kunal_03 from '../images/photos/milouni_kunal/C288.jpg'
import milouni_kunal_04 from '../images/photos/milouni_kunal/R19.jpg'
import milouni_kunal_05 from '../images/photos/milouni_kunal/R34.jpg'
import milouni_kunal_06 from '../images/photos/milouni_kunal/R43.jpg'
import milouni_kunal_07 from '../images/photos/milouni_kunal/R47.jpg'
import milouni_kunal_08 from '../images/photos/milouni_kunal/W166.jpg'
import milouni_kunal_09 from '../images/photos/milouni_kunal/W200.jpg'
import milouni_kunal_10 from '../images/photos/milouni_kunal/W250.jpg'
import milouni_kunal_11 from '../images/photos/milouni_kunal/W262.jpg'
import milouni_kunal_12 from '../images/photos/milouni_kunal/W352.jpg'
import milouni_kunal_13 from '../images/photos/milouni_kunal/W373.jpg'
import milouni_kunal_14 from '../images/photos/milouni_kunal/W415.jpg'
import milouni_kunal_15 from '../images/photos/milouni_kunal/W438.jpg'
import milouni_kunal_16 from '../images/photos/milouni_kunal/W449.jpg'
import milouni_kunal_17 from '../images/photos/milouni_kunal/W474.jpg'
import milouni_kunal_18 from '../images/photos/milouni_kunal/W742.jpg'
import milouni_kunal_19 from '../images/photos/milouni_kunal/W889.jpg'
import milouni_kunal_20 from '../images/photos/milouni_kunal/W912.jpg'


import rg10 from '../images/photos/rg/IMG-7.jpg'
import rg11 from '../images/photos/rg/IMG-13.jpg'
import rg12 from '../images/photos/rg/IMG-27.jpg'
import rg13 from '../images/photos/rg/IMG-28.jpg'
import rg14 from '../images/photos/rg/IMG-41.jpg'
import rg15 from '../images/photos/rg/IMG-127.jpg'
import rg16 from '../images/photos/rg/IMG-164.jpg'
import rg17 from '../images/photos/rg/IMG-173.jpg'
import rg18 from '../images/photos/rg/IMG-269.jpg'
import rg19 from '../images/photos/rg/IMG-431.jpg'

import other_01 from '../images/photos/other/01.jpg'
import other_02 from '../images/photos/other/02.jpg'
import other_03 from '../images/photos/other/03.jpg'
import other_04 from '../images/photos/other/04.jpg'
import other_05 from '../images/photos/other/05.jpg'
import other_06 from '../images/photos/other/06.jpg'
import other_07 from '../images/photos/other/07.jpg'
import other_08 from '../images/photos/other/08.jpg'
import other_09 from '../images/photos/other/09.jpg'
import other_10 from '../images/photos/other/10.jpg'

import other_11 from '../images/photos/other/11.jpg'
import other_12 from '../images/photos/other/12.jpg'
import other_13 from '../images/photos/other/13.jpg'
import other_14 from '../images/photos/other/14.jpg'
import other_15 from '../images/photos/other/15.jpg'
import other_16 from '../images/photos/other/16.jpg'
import other_17 from '../images/photos/other/17.jpg'
import other_18 from '../images/photos/other/18.jpg'
import other_19 from '../images/photos/other/19.jpg'
import other_20 from '../images/photos/other/20.jpg'


import other_21 from '../images/photos/other/21.jpg'
import other_22 from '../images/photos/other/22.jpg'
import other_23 from '../images/photos/other/23.jpg'
import other_24 from '../images/photos/other/24.jpg'
import other_25 from '../images/photos/other/25.jpg'
import other_26 from '../images/photos/other/26.jpg'
import other_27 from '../images/photos/other/27.jpg'
import other_28 from '../images/photos/other/28.jpg'
import other_29 from '../images/photos/other/29.jpg'
import other_30 from '../images/photos/other/30.jpg'

import other_31 from '../images/photos/other/31.jpg'
import other_32 from '../images/photos/other/32.jpg'
import other_33 from '../images/photos/other/33.jpg'
import other_34 from '../images/photos/other/34.jpg'
import other_35 from '../images/photos/other/35.jpg'
import other_36 from '../images/photos/other/36.jpg'
import other_37 from '../images/photos/other/37.jpg'
import other_38 from '../images/photos/other/38.jpg'
import other_39 from '../images/photos/other/39.jpg'
import other_40 from '../images/photos/other/40.jpg'

import other_41 from '../images/photos/other/41.jpg'
import other_42 from '../images/photos/other/42.jpg'
import other_43 from '../images/photos/other/43.jpg'
import other_44 from '../images/photos/other/44.jpg'
import other_45 from '../images/photos/other/45.jpg'
import other_46 from '../images/photos/other/46.jpg'
import other_47 from '../images/photos/other/47.jpg'
import other_48 from '../images/photos/other/48.jpg'
import other_49 from '../images/photos/other/49.jpg'
import other_50 from '../images/photos/other/50.jpg'

import other_51 from '../images/photos/other/51.jpg'
import other_52 from '../images/photos/other/52.jpg'
import other_53 from '../images/photos/other/53.jpg'
import other_54 from '../images/photos/other/54.jpg'
import other_55 from '../images/photos/other/55.jpg'
import other_56 from '../images/photos/other/56.jpg'
import other_57 from '../images/photos/other/57.jpg'
import other_58 from '../images/photos/other/58.jpg'
import other_59 from '../images/photos/other/59.jpg'
import other_60 from '../images/photos/other/60.jpg'

import other_61 from '../images/photos/other/61.jpg'
import other_62 from '../images/photos/other/62.jpg'
import other_63 from '../images/photos/other/63.jpg'
import other_64 from '../images/photos/other/64.jpg'
import other_65 from '../images/photos/other/65.jpg'
import other_66 from '../images/photos/other/66.jpg'
import other_67 from '../images/photos/other/67.jpg'
import other_68 from '../images/photos/other/68.jpg'
import other_69 from '../images/photos/other/69.jpg'
import other_70 from '../images/photos/other/70.jpg'

import other_71 from '../images/photos/other/71.jpg'
import other_72 from '../images/photos/other/72.jpg'
import other_73 from '../images/photos/other/73.jpg'
import other_74 from '../images/photos/other/74.jpg'
import other_75 from '../images/photos/other/75.jpg'
import other_76 from '../images/photos/other/76.jpg'
import other_77 from '../images/photos/other/77.jpg'
import other_78 from '../images/photos/other/78.jpg'

import katha_01 from '../images/photos/katha/AMAR0167.jpg'
import katha_02 from '../images/photos/katha/AMAR0970.jpg'
import katha_03 from '../images/photos/katha/AMAR1268.jpg'
import katha_04 from '../images/photos/katha/AMAR1533.jpg'
import katha_05 from '../images/photos/katha/AMAR8911.jpg'
import katha_06 from '../images/photos/katha/AMAR8980.jpg'
import katha_07 from '../images/photos/katha/AMAR8985.jpg'
import katha_08 from '../images/photos/katha/AMAR9488.jpg'
import katha_09 from '../images/photos/katha/AMAR9512.jpg'
import katha_10 from '../images/photos/katha/AMAR9521.jpg'
import katha_11 from '../images/photos/katha/AMAR9538.jpg'
import katha_12 from '../images/photos/katha/ANIL3188.jpg'
import katha_13 from '../images/photos/katha/ANIL3258.jpg'
import katha_14 from '../images/photos/katha/ANIL4795.jpg'
import katha_15 from '../images/photos/katha/ANIL4836.jpg'
import katha_16 from '../images/photos/katha/ANIL5117.jpg'
import katha_17 from '../images/photos/katha/ANIL5888.jpg'
import katha_18 from '../images/photos/katha/ANIL5948.jpg'
import katha_19 from '../images/photos/katha/ANIL5986.jpg'
import katha_20 from '../images/photos/katha/ANIL6007.jpg'
import katha_21 from '../images/photos/katha/ANIL6041.jpg'
import katha_22 from '../images/photos/katha/ANIL6046.jpg'
import katha_23 from '../images/photos/katha/ANIL62583.jpg'
import katha_24 from '../images/photos/katha/ANIL8088.jpg'
import katha_25 from '../images/photos/katha/ANIL9182.jpg'
import katha_26 from '../images/photos/katha/ANIL9559.jpg'


import katha_27 from '../images/photos/katha/AMAR0068.jpg'
import katha_28 from '../images/photos/katha/AMAR0077.jpg'
import katha_29 from '../images/photos/katha/AMAR0154.jpg'
import katha_30 from '../images/photos/katha/AMAR0185.jpg'
import katha_31 from '../images/photos/katha/AMAR0192.jpg'
import katha_32 from '../images/photos/katha/AMAR0238.jpg'
import katha_33 from '../images/photos/katha/AMAR0592.jpg'
import katha_34 from '../images/photos/katha/AMAR0694.jpg'
import katha_35 from '../images/photos/katha/AMAR1835.jpg'
import katha_36 from '../images/photos/katha/AMAR1920.jpg'
import katha_37 from '../images/photos/katha/AMAR2296.jpg'
import katha_38 from '../images/photos/katha/AMAR2801.jpg'
import katha_39 from '../images/photos/katha/AMAR2990.jpg'
import katha_40 from '../images/photos/katha/AMAR8781.jpg'
import katha_41 from '../images/photos/katha/AMAR9650.jpg'
import katha_42 from '../images/photos/katha/AMAR9774.jpg'
import katha_43 from '../images/photos/katha/AMAR9830.jpg'
import katha_44 from '../images/photos/katha/ANIL3138.jpg'
import katha_45 from '../images/photos/katha/ANIL4180.jpg'
import katha_46 from '../images/photos/katha/ANIL4247.jpg'
import katha_47 from '../images/photos/katha/ANIL5743.jpg'



let data_photos = [
    {      id:1,name:"Suvidhi & Rishabh - Haldi 1",image:haladi_01,details:""},
    {        id:2,name:"Suvidhi & Rishabh - Haldi 2",image:haladi_02,details:""},
      {
        id:3,
        name:"Suvidhi & Rishabh - Haldi 3",
        image:haladi_03,
        details:"",
      },
      {
        id:4,
        name:"Suvidhi & Rishabh - Haldi 4",
        image:haladi_04,
        details:"",
      },
      {
        id:5,
        name:"Suvidhi & Rishabh - Haldi 5",
        image:haladi_05,
        details:"",
      },
      {
        id:6,
        name:"Suvidhi & Rishabh - Haldi 6",
        image:haladi_06,
        details:"",
      },
      {
        id:7,
        name:"Suvidhi & Rishabh - Haldi 7",
        image:haladi_07,
        details:"",
      },
      {
        id:8,
        name:"Suvidhi & Rishabh - Haldi 8",
        image:haladi_08,
        details:"",
      },
      {
        id:9,
        name:"Suvidhi & Rishabh - Haldi 9",
        image:haladi_09,
        details:"",
      },
      {
        id:10,
        name:"Suvidhi & Rishabh - Haldi 10",
        image:haladi_10,
        details:"",
      },
      {
        id:11,
        name:"Suvidhi & Rishabh - Haldi 11",
        image:haladi_11,
        details:"",
      },
    
      {
        id:12,
        name:"Suvidhi & Rishabh - Reception 12",
        image:reception_01,
        details:"",
      },
      {
        id:13,
        name:"Suvidhi & Rishabh - Reception 13",
        image:reception_02,
        details:"",
      },
      {
        id:14,
        name:"Suvidhi & Rishabh - Reception 14",
        image:reception_03,
        details:"",
      },
      {
        id:15,
        name:"Suvidhi & Rishabh - Reception 15",
        image:reception_04,
        details:"",
      },
      {
        id:16,
        name:"Suvidhi & Rishabh - Reception 16",
        image:reception_05,
        details:"",
      },
      {
        id:17,
        name:"Suvidhi & Rishabh - Reception 17",
        image:reception_06,
        details:"",
      },
      {
        id:18,
        name:"Suvidhi & Rishabh - Reception 18",
        image:reception_07,
        details:"",
      },
      {
        id:18,
        name:"Suvidhi & Rishabh - Reception 19",
        image:reception_08,
        details:"",
      },
      {id:18, name:"Suvidhi & Rishabh - Wedding 20", image:wedding_01, details:""},
      {id:19, name:"Suvidhi & Rishabh - Wedding 21", image:wedding_02, details:""},
      {id:20, name:"Suvidhi & Rishabh - Wedding 22", image:wedding_03, details:""},
      {id:21, name:"Suvidhi & Rishabh - Wedding 23", image:wedding_04, details:""},
      {id:22, name:"Suvidhi & Rishabh - Wedding 24", image:wedding_05, details:""},
      {id:23, name:"Suvidhi & Rishabh - Wedding 25", image:wedding_06, details:""},
      {id:24, name:"Suvidhi & Rishabh - Wedding 26", image:wedding_07, details:""},
      {id:25, name:"Suvidhi & Rishabh - Wedding 27", image:wedding_08, details:""},
      {id:26, name:"Suvidhi & Rishabh - Wedding 28", image:wedding_09, details:""},
      {id:27, name:"Suvidhi & Rishabh - Wedding 29", image:wedding_10, details:""},
      {id:28, name:"Suvidhi & Rishabh - Wedding 30", image:wedding_11, details:""},
      {id:29, name:"Suvidhi & Rishabh - Wedding 31", image:wedding_12, details:""},
      {id:30, name:"Suvidhi & Rishabh - Wedding 32", image:wedding_13, details:""},
      {id:31, name:"Suvidhi & Rishabh - Wedding 33", image:wedding_14, details:""},
      {id:32, name:"Suvidhi & Rishabh - Wedding 34", image:wedding_15, details:""},
      {id:33, name:"Suvidhi & Rishabh - Wedding 35", image:wedding_16, details:""},
      {id:34, name:"Suvidhi & Rishabh - Wedding 36", image:wedding_17, details:""},
      {id:35, name:"Suvidhi & Rishabh - Wedding 37", image:wedding_18, details:""},

      {id:36, name:"Rohan & Neelam - Wedding 38", image:rn_01, details:""},
      {id:37, name:"Rohan & Neelam - Wedding 39", image:rn_02, details:""},
      {id:38, name:"Rohan & Neelam - Wedding 40", image:rn_03, details:""},
      {id:39, name:"Rohan & Neelam - Wedding 41", image:rn_04, details:""},
      {id:40, name:"Rohan & Neelam - Wedding 42", image:rn_05, details:""},

      {id:41, name:"Rohan & Neelam - Wedding 43", image:rn_06, details:""},
      {id:42, name:"Rohan & Neelam - Wedding 44", image:rn_07, details:""},
      {id:43, name:"Rohan & Neelam - Wedding 45", image:rn_08, details:""},
      {id:44, name:"Rohan & Neelam - Wedding 46", image:rn_09, details:""},
      {id:45, name:"Rohan & Neelam - Wedding 47", image:rn_10, details:""},
      {id:46, name:"Rohan & Neelam - Wedding 48", image:rn_11, details:""},
      {id:47, name:"Rohan & Neelam - Wedding 49", image:rn_12, details:""},
      {id:48, name:"Rohan & Neelam - Wedding 50", image:rn_13, details:""},
      {id:49, name:"Rohan & Neelam - Wedding 51", image:rn_14, details:""},
      {id:50, name:"Rohan & Neelam - Wedding 52", image:rn_15, details:""},
      
      {id:51, name:"Rohan & Neelam - Wedding 53", image:rn_16, details:""},
      {id:52, name:"Rohan & Neelam - Wedding 54", image:rn_17, details:""},
      {id:53, name:"Rohan & Neelam - Wedding 55", image:rn_18, details:""},
      {id:54, name:"Rohan & Neelam - Wedding 56", image:rn_19, details:""},
      {id:55, name:"Rohan & Neelam - Wedding 57", image:rn_20, details:""},
      {id:56, name:"Rohan & Neelam - Wedding 58", image:rn_21, details:""},
      {id:57, name:"Rohan & Neelam - Wedding 59", image:rn_22, details:""},
      {id:58, name:"Rohan & Neelam - Wedding 60", image:rn_23, details:""},
      {id:59, name:"Rohan & Neelam - Wedding 61", image:rn_24, details:""},
      {id:60, name:"Rohan & Neelam - Wedding 62", image:rn_25, details:""},

      {id:61, name:"Rohan & Neelam - Wedding 63", image:rn_26, details:""},
      {id:62, name:"Rohan & Neelam - Wedding 64", image:rn_27, details:""},
      {id:63, name:"Rohan & Neelam - Wedding 65", image:rn_28, details:""},
      {id:64, name:"Rohan & Neelam - Wedding 66", image:rn_29, details:""},
      {id:65, name:"Rohan & Neelam - Wedding 67", image:rn_30, details:""},
      {id:66, name:"Rohan & Neelam - Wedding 68", image:rn_31, details:""},
      {id:67, name:"Rohan & Neelam - Wedding 69", image:rn_32, details:""},
      {id:68, name:"Rohan & Neelam - Wedding 70", image:rn_33, details:""},
      {id:70, name:"Rohan & Neelam - Wedding 71", image:rn_35, details:""},
      {id:71, name:"Rohan & Neelam - Wedding 72", image:rn_36, details:""},
      {id:72, name:"Rohan & Neelam - Wedding 73", image:rn_37, details:""},
      {id:73, name:"Rohan & Neelam - Wedding 74", image:rn_38, details:""},
      {id:74, name:"Rohan & Neelam - Wedding 75", image:rn_39, details:""},
      {id:75, name:"Rohan & Neelam - Wedding 76", image:rn_40, details:""},
      {id:76, name:"Rohan & Neelam - Wedding 77", image:rn_41, details:""},
      {id:77, name:"Meghna & Arun - Wedding 78", image:ma_411, details:""},
      {id:78, name:"Meghna & Arun - Wedding 79", image:ma_42, details:""},
      {id:79, name:"Meghna & Arun - Wedding 80", image:ma_43, details:""},
      {id:80, name:"Meghna & Arun - Wedding 81", image:ma_44, details:""},
      {id:81, name:"Meghna & Arun - Wedding 82", image:ma_45, details:""},
      {id:82, name:"Meghna & Arun - Wedding 83", image:ma_46, details:""},
      {id:83, name:"Meghna & Arun - Wedding 84", image:ma_47, details:""},
      {id:84, name:"Meghna & Arun - Wedding 85", image:ma_48, details:""},
      {id:85, name:"Meghna & Arun - Wedding 86", image:ma_49, details:""},
      {id:86, name:"Meghna & Arun - Wedding 87", image:ma_50, details:""},
      {id:87, name:"Meghna & Arun - Wedding 88", image:ma_51, details:""},
      {id:88, name:"Meghna & Arun - Wedding 89", image:ma_52, details:""},
      {id:89, name:"Meghna & Arun - Wedding 90", image:ma_53, details:""},
      {id:90, name:"Meghna & Arun - Wedding 91", image:ma_54, details:""},
      {id:91, name:"Meghna & Arun - Wedding 92", image:ma_55, details:""},
      {id:92, name:"Meghna & Arun - Wedding 93", image:ma_56, details:""},
      {id:93, name:"Meghna & Arun - Wedding 94", image:ma_57, details:""},
      {id:94, name:"Meghna & Arun - Wedding 95", image:ma_58, details:""},
      {id:95, name:"Meghna & Arun - Wedding 96", image:ma_59, details:""},
      {id:96, name:"Meghna & Arun - Wedding 97", image:ma_60, details:""},
      {id:97, name:"Meghna & Arun - Wedding 98", image:ma_61, details:""},
      {id:98, name:"Meghna & Arun - Wedding 99", image:ma_62, details:""},
      {id:99, name:"Meghna & Arun - Wedding 100", image:ma_63, details:""},
      {id:100, name:"Meghna & Arun - Wedding 101", image:ma_64, details:""},
      {id:101, name:"Meghna & Arun - Wedding 102", image:ma_65, details:""},



      {id:102, name:"Shalu & Reza - Wedding 103", image:shalu_reza_01, details:""},
      {id:103, name:"Shalu & Reza - Wedding 104", image:shalu_reza_02, details:""},
      {id:104, name:"Shalu & Reza - Wedding 105", image:shalu_reza_03, details:""},
      {id:105, name:"Shalu & Reza - Wedding 106", image:shalu_reza_04, details:""},
      {id:106, name:"Shalu & Reza - Wedding 107", image:shalu_reza_05, details:""},
      {id:107, name:"Shalu & Reza - Wedding 108", image:shalu_reza_06, details:""},
      {id:108, name:"Shalu & Reza - Wedding 109", image:shalu_reza_07, details:""},
      {id:109, name:"Shalu & Reza - Wedding 110", image:shalu_reza_08, details:""},
      {id:110, name:"Shalu & Reza - Wedding 111", image:shalu_reza_09, details:""},
      {id:111, name:"Shalu & Reza - Wedding 112", image:shalu_reza_10, details:""},
      {id:112, name:"Shalu & Reza - Wedding 113", image:shalu_reza_11, details:""},
      {id:113, name:"Shalu & Reza - Wedding 114", image:shalu_reza_12, details:""},
      {id:114, name:"Shalu & Reza - Wedding 115", image:shalu_reza_13, details:""},
      {id:115, name:"Shalu & Reza - Wedding 116", image:shalu_reza_14, details:""},
      {id:116, name:"Shalu & Reza - Wedding 117", image:shalu_reza_15, details:""},
      {id:117, name:"Shalu & Reza - Wedding 118", image:shalu_reza_16, details:""},
      {id:118, name:"Shalu & Reza - Wedding 119", image:shalu_reza_17, details:""},
      {id:119, name:"Shalu & Reza - Wedding 120", image:shalu_reza_18, details:""},
      {id:120, name:"Shalu & Reza - Wedding 121", image:shalu_reza_19, details:""},
      {id:121, name:"Shalu & Reza - Wedding 122", image:shalu_reza_20, details:""},
      {id:122, name:"Shalu & Reza - Wedding 123", image:shalu_reza_21, details:""},
      {id:123, name:"Shalu & Reza - Wedding 124", image:shalu_reza_22, details:""},
      {id:124, name:"Shalu & Reza - Wedding 125", image:shalu_reza_23, details:""},
      {id:125, name:"Shalu & Reza - Wedding 126", image:shalu_reza_24, details:""},
      {id:126, name:"Shalu & Reza - Wedding 127", image:shalu_reza_25, details:""},
      {id:127, name:"Shalu & Reza - Wedding 128", image:shalu_reza_26, details:""},
      {id:128, name:"Shalu & Reza - Wedding 129", image:shalu_reza_27, details:""},
      {id:129, name:"Shalu & Reza - Wedding 130", image:shalu_reza_28, details:""},
      {id:130, name:"Shalu & Reza - Wedding 131", image:shalu_reza_29, details:""},
      {id:131, name:"Shalu & Reza - Wedding 132", image:shalu_reza_30, details:""},
      {id:132, name:"Shalu & Reza - Wedding 133", image:shalu_reza_31, details:""},
      {id:133, name:"Shalu & Reza - Wedding 134", image:shalu_reza_32, details:""},
      {id:134, name:"Shalu & Reza - Wedding 135", image:shalu_reza_33, details:""},
 
      {id:135, name:"Jenifer & Nikcet - Wedding 136", image:jenifer_nikcet_01, details:""},
      {id:136, name:"Jenifer & Nikcet - Wedding 137", image:jenifer_nikcet_02, details:""},
      {id:137, name:"Jenifer & Nikcet - Wedding 138", image:jenifer_nikcet_03, details:""},
      {id:138, name:"Jenifer & Nikcet - Wedding 139", image:jenifer_nikcet_04, details:""},
      {id:139, name:"Jenifer & Nikcet - Wedding 140", image:jenifer_nikcet_05, details:""},
      {id:140, name:"Jenifer & Nikcet - Wedding 141", image:jenifer_nikcet_06, details:""},
      {id:141, name:"Jenifer & Nikcet - Wedding 142", image:jenifer_nikcet_07, details:""},
      {id:142, name:"Jenifer & Nikcet - Wedding 143", image:jenifer_nikcet_08, details:""},
      {id:143, name:"Jenifer & Nikcet - Wedding 144", image:jenifer_nikcet_09, details:""},
      {id:144, name:"Jenifer & Nikcet - Wedding 145", image:jenifer_nikcet_10, details:""},
      {id:145, name:"Jenifer & Nikcet - Wedding 146", image:jenifer_nikcet_11, details:""},
      {id:146, name:"Jenifer & Nikcet - Wedding 147", image:jenifer_nikcet_12, details:""},
      {id:147, name:"Jenifer & Nikcet - Wedding 148", image:jenifer_nikcet_13, details:""},
      {id:148, name:"Jenifer & Nikcet - Wedding 149", image:jenifer_nikcet_14, details:""},
      {id:149, name:"Jenifer & Nikcet - Wedding 150", image:jenifer_nikcet_15, details:""},
      {id:150, name:"Jenifer & Nikcet - Wedding 151", image:jenifer_nikcet_16, details:""},
      {id:151, name:"Jenifer & Nikcet - Wedding 152", image:jenifer_nikcet_17, details:""},
      {id:152, name:"Jenifer & Nikcet - Wedding 153", image:jenifer_nikcet_18, details:""},
      {id:153, name:"Jenifer & Nikcet - Wedding 154", image:jenifer_nikcet_19, details:""},
      {id:154, name:"Jenifer & Nikcet - Wedding 155", image:jenifer_nikcet_20, details:""},



      {id:155, name:"Abhash & Shruti - Wedding 156", image:abhash_shruti_01, details:""},
      {id:156, name:"Abhash & Shruti - Wedding 157", image:abhash_shruti_02, details:""},
      {id:157, name:"Abhash & Shruti - Wedding 158", image:abhash_shruti_03, details:""},
      {id:158, name:"Abhash & Shruti - Wedding 159", image:abhash_shruti_04, details:""},
      {id:159, name:"Abhash & Shruti - Wedding 160", image:abhash_shruti_05, details:""},
      {id:160, name:"Abhash & Shruti - Wedding 161", image:abhash_shruti_06, details:""},
      {id:161, name:"Abhash & Shruti - Wedding 162", image:abhash_shruti_07, details:""},
      {id:162, name:"Abhash & Shruti - Wedding 163", image:abhash_shruti_08, details:""},
      {id:163, name:"Abhash & Shruti - Wedding 164", image:abhash_shruti_09, details:""},
      {id:164, name:"Abhash & Shruti - Wedding 165", image:abhash_shruti_10, details:""},
      {id:165, name:"Abhash & Shruti - Wedding 166", image:abhash_shruti_11, details:""},
      {id:166, name:"Abhash & Shruti - Wedding 167", image:abhash_shruti_12, details:""},
      {id:167, name:"Abhash & Shruti - Wedding 168", image:abhash_shruti_13, details:""},
      {id:168, name:"Abhash & Shruti - Wedding 169", image:abhash_shruti_14, details:""},
      {id:169, name:"Abhash & Shruti - Wedding 170", image:abhash_shruti_15, details:""},
      {id:170, name:"Abhash & Shruti - Wedding 171", image:abhash_shruti_16, details:""},
      {id:171, name:"Abhash & Shruti - Wedding 172", image:abhash_shruti_17, details:""},
      {id:172, name:"Abhash & Shruti - Wedding 173", image:abhash_shruti_18, details:""},
      {id:173, name:"Abhash & Shruti - Wedding 174", image:abhash_shruti_19, details:""},
      {id:174, name:"Abhash & Shruti - Wedding 175", image:abhash_shruti_20, details:""},
      {id:175, name:"Abhash & Shruti - Wedding 176", image:abhash_shruti_21, details:""},
      {id:176, name:"Abhash & Shruti - Wedding 178", image:abhash_shruti_22, details:""},
      {id:177, name:"Abhash & Shruti - Wedding 179", image:abhash_shruti_23, details:""},
      {id:178, name:"Abhash & Shruti - Wedding 180", image:abhash_shruti_24, details:""},
      {id:179, name:"Abhash & Shruti - Wedding 181", image:abhash_shruti_25, details:""},
      {id:180, name:"Abhash & Shruti - Wedding 182", image:abhash_shruti_26, details:""},
      {id:181, name:"Abhash & Shruti - Wedding 183", image:abhash_shruti_27, details:""},
      {id:182, name:"Abhash & Shruti - Wedding 184", image:abhash_shruti_28, details:""},




      {id:183, name:"Laya & Shivank - Wedding 185", image:laya_shivank_01, details:""},
      {id:184, name:"Laya & Shivank - Wedding 186", image:laya_shivank_02, details:""},
      {id:185, name:"Laya & Shivank - Wedding 187", image:laya_shivank_03, details:""},
      {id:186, name:"Laya & Shivank - Wedding 188", image:laya_shivank_04, details:""},
      {id:187, name:"Laya & Shivank - Wedding 189", image:laya_shivank_05, details:""},
      {id:188, name:"Laya & Shivank - Wedding 190", image:laya_shivank_06, details:""},
      {id:189, name:"Laya & Shivank - Wedding 191", image:laya_shivank_07, details:""},
      {id:190, name:"Laya & Shivank - Wedding 192", image:laya_shivank_08, details:""},
      {id:191, name:"Laya & Shivank - Wedding 193", image:laya_shivank_09, details:""},
      {id:192, name:"Laya & Shivank - Wedding 194", image:laya_shivank_10, details:""},
      {id:193, name:"Laya & Shivank - Wedding 195", image:laya_shivank_11, details:""},
      {id:194, name:"Laya & Shivank - Wedding 196", image:laya_shivank_12, details:""},
      {id:195, name:"Laya & Shivank - Wedding 197", image:laya_shivank_13, details:""},
      {id:196, name:"Laya & Shivank - Wedding 198", image:laya_shivank_14, details:""},
      {id:197, name:"Laya & Shivank - Wedding 199", image:laya_shivank_15, details:""},
      {id:198, name:"Laya & Shivank - Wedding 200", image:laya_shivank_16, details:""},
      {id:199, name:"Laya & Shivank - Wedding 201", image:laya_shivank_17, details:""},
      {id:200, name:"Laya & Shivank - Wedding 202", image:laya_shivank_18, details:""},
 
      
      {id:201, name:"Milouni & Kunal - Wedding 203", image:milouni_kunal_01, details:""},
      {id:202, name:"Milouni & Kunal - Wedding 204", image:milouni_kunal_02, details:""},
      {id:203, name:"Milouni & Kunal - Wedding 205", image:milouni_kunal_03, details:""},
      {id:204, name:"Milouni & Kunal - Wedding 206", image:milouni_kunal_04, details:""},
      {id:205, name:"Milouni & Kunal - Wedding 207", image:milouni_kunal_05, details:""},
      {id:206, name:"Milouni & Kunal - Wedding 208", image:milouni_kunal_06, details:""},
      {id:207, name:"Milouni & Kunal - Wedding 209", image:milouni_kunal_07, details:""},
      {id:208, name:"Milouni & Kunal - Wedding 210", image:milouni_kunal_08, details:""},
      {id:209, name:"Milouni & Kunal - Wedding 211", image:milouni_kunal_09, details:""},
      {id:210, name:"Milouni & Kunal - Wedding 212", image:milouni_kunal_10, details:""},
      {id:211, name:"Milouni & Kunal - Wedding 213", image:milouni_kunal_11, details:""},
      {id:212, name:"Milouni & Kunal - Wedding 214", image:milouni_kunal_12, details:""},
      {id:213, name:"Milouni & Kunal - Wedding 215", image:milouni_kunal_13, details:""},
      {id:214, name:"Milouni & Kunal - Wedding 216", image:milouni_kunal_14, details:""},
      {id:215, name:"Milouni & Kunal - Wedding 217", image:milouni_kunal_15, details:""},
      {id:216, name:"Milouni & Kunal - Wedding 218", image:milouni_kunal_16, details:""},
      {id:217, name:"Milouni & Kunal - Wedding 219", image:milouni_kunal_17, details:""},
      {id:218, name:"Milouni & Kunal - Wedding 220", image:milouni_kunal_18, details:""},
      {id:219, name:"Milouni & Kunal - Wedding 221", image:milouni_kunal_19, details:""},
      {id:220, name:"Milouni & Kunal - Wedding 222", image:milouni_kunal_20, details:""},
   
       {id:221, name:"RG - Wedding 223", image:rg10, details:""},
      {id:221, name:"RG - Wedding 224", image:rg11, details:""},
      {id:221, name:"RG - Wedding 225", image:rg12, details:""},
      {id:221, name:"RG - Wedding 226", image:rg13, details:""},
      {id:221, name:"RG - Wedding 227", image:rg14, details:""},
      {id:221, name:"RG - Wedding 228", image:rg15, details:""},
      {id:221, name:"RG - Wedding 229", image:rg16, details:""},
      {id:221, name:"RG - Wedding 230", image:rg17, details:""},
      {id:221, name:"RG - Wedding 231", image:rg18, details:""},
      {id:221, name:"RG - Wedding 232", image:rg19, details:""},


      {id:245, name:"Other -  233", image:other_01, details:""},
      {id:245, name:"Other -  234", image:other_02, details:""},
      {id:245, name:"Other -  235", image:other_03, details:""},
      {id:245, name:"Other -  236", image:other_04, details:""},
      {id:245, name:"Other -  237", image:other_05, details:""},
      {id:245, name:"Other -  238", image:other_06, details:""},
      {id:245, name:"Other -  239", image:other_07, details:""},
      {id:245, name:"Other -  240", image:other_08, details:""},
      {id:245, name:"Other -  241", image:other_09, details:""},
      {id:245, name:"Other -  242", image:other_10, details:""},


      {id:245, name:"Other -  243 ", image:other_11, details:""},
      {id:245, name:"Other -  244 ", image:other_12, details:""},
      {id:245, name:"Other -  245 ", image:other_13, details:""},
      {id:245, name:"Other -  246 ", image:other_14, details:""},
      {id:245, name:"Other -  247 ", image:other_15, details:""},
      {id:245, name:"Other -  248 ", image:other_16, details:""},
      {id:245, name:"Other -  249 ", image:other_17, details:""},
      {id:245, name:"Other -  250 ", image:other_18, details:""},
      {id:245, name:"Other -  251 ", image:other_19, details:""},
      {id:245, name:"Other -  252 ", image:other_20, details:""},

      {id:245, name:"Other -  253 ", image:other_21, details:""},
      {id:245, name:"Other -  254 ", image:other_22, details:""},
      {id:245, name:"Other -  255 ", image:other_23, details:""},
      {id:245, name:"Other -  256 ", image:other_24, details:""},
      {id:245, name:"Other -  257 ", image:other_25, details:""},
      {id:245, name:"Other -  258 ", image:other_26, details:""},
      {id:245, name:"Other -  259 ", image:other_27, details:""},
      {id:245, name:"Other -  260 ", image:other_28, details:""},
      {id:245, name:"Other -  261 ", image:other_29, details:""},
      {id:245, name:"Other -  262 ", image:other_30, details:""},

      {id:245, name:"Other -  263 ", image:other_31, details:""},
      {id:245, name:"Other -  264 ", image:other_32, details:""},
      {id:245, name:"Other -  265 ", image:other_33, details:""},
      {id:245, name:"Other -  266 ", image:other_34, details:""},
      {id:245, name:"Other -  267 ", image:other_35, details:""},
      {id:245, name:"Other -  268 ", image:other_36, details:""},
      {id:245, name:"Other -  269 ", image:other_37, details:""},
      {id:245, name:"Other -  270 ", image:other_38, details:""},
      {id:245, name:"Other -  271 ", image:other_39, details:""},
      {id:245, name:"Other -  272 ", image:other_40, details:""},
      {id:245, name:"Other -  273 ", image:other_41, details:""},
      {id:245, name:"Other -  274 ", image:other_42, details:""},
      {id:245, name:"Other -  275 ", image:other_43, details:""},
      {id:245, name:"Other -  276 ", image:other_44, details:""},
      {id:245, name:"Other -  277 ", image:other_45, details:""},
      {id:245, name:"Other -  278 ", image:other_46, details:""},
      {id:245, name:"Other -  279 ", image:other_47, details:""},
      {id:245, name:"Other -  280 ", image:other_48, details:""},
      {id:245, name:"Other -  281 ", image:other_49, details:""},
      {id:245, name:"Other -  282 ", image:other_50, details:""},
      {id:245, name:"Other -  283 ", image:other_51, details:""},
      {id:245, name:"Other -  284 ", image:other_52, details:""},
      {id:245, name:"Other -  285 ", image:other_53, details:""},
      {id:245, name:"Other -  286 ", image:other_54, details:""},
      {id:245, name:"Other -  287 ", image:other_55, details:""},
      {id:245, name:"Other -  288 ", image:other_56, details:""},
      {id:245, name:"Other -  289 ", image:other_57, details:""},
      {id:245, name:"Other -  290 ", image:other_58, details:""},
      {id:245, name:"Other -  291 ", image:other_59, details:""},
      {id:245, name:"Other -  292 ", image:other_60, details:""},
      {id:245, name:"Other -  293 ", image:other_61, details:""},
      {id:245, name:"Other -  294 ", image:other_62, details:""},
      {id:245, name:"Other -  295 ", image:other_63, details:""},
      {id:245, name:"Other -  296 ", image:other_64, details:""},
      {id:245, name:"Other -  297 ", image:other_65, details:""},
      {id:245, name:"Other -  298 ", image:other_66, details:""},
      {id:245, name:"Other -  299 ", image:other_67, details:""},
      {id:245, name:"Other -  300 ", image:other_68, details:""},
      {id:245, name:"Other -  301 ", image:other_69, details:""},
      {id:245, name:"Other -  302 ", image:other_70, details:""},
      {id:245, name:"Other -  303 ", image:other_71, details:""},
      {id:245, name:"Other -  304 ", image:other_72, details:""},
      {id:245, name:"Other -  305 ", image:other_73, details:""},
      {id:245, name:"Other -  306 ", image:other_74, details:""},
      {id:245, name:"Other -  307 ", image:other_75, details:""},
      {id:245, name:"Other -  308 ", image:other_76, details:""},
      {id:245, name:"Other -  309 ", image:other_77, details:""},
      {id:245, name:"Other -  310 ", image:other_78, details:""},
      {id:245, name:"Katha -  311 ", image:katha_01, details:""},
      {id:245, name:"Katha -  312 ", image:katha_02, details:""},
      {id:245, name:"Katha -  313 ", image:katha_03, details:""},
      {id:245, name:"Katha -  314 ", image:katha_04, details:""},
      {id:245, name:"Katha -  315 ", image:katha_05, details:""},
      {id:245, name:"Katha -  316 ", image:katha_06, details:""},
      {id:245, name:"Katha -  317 ", image:katha_07, details:""},
      {id:245, name:"Katha -  318 ", image:katha_08, details:""},
      {id:245, name:"Katha -  319 ", image:katha_09, details:""},
      {id:245, name:"Katha -  320 ", image:katha_10, details:""},
      {id:245, name:"Katha -  321 ", image:katha_11, details:""},
      {id:245, name:"Katha -  322 ", image:katha_12, details:""},
      {id:245, name:"Katha -  323 ", image:katha_13, details:""},
      {id:245, name:"Katha -  324 ", image:katha_14, details:""},
      {id:245, name:"Katha -  325 ", image:katha_15, details:""},
      {id:245, name:"Katha -  326 ", image:katha_16, details:""},
      {id:245, name:"Katha -  327 ", image:katha_17, details:""},
      {id:245, name:"Katha -  328 ", image:katha_18, details:""},
      {id:245, name:"Katha -  329 ", image:katha_19, details:""},
      {id:245, name:"Katha -  330 ", image:katha_20, details:""},
      {id:245, name:"Katha -  331 ", image:katha_21, details:""},
      {id:245, name:"Katha -  332 ", image:katha_22, details:""},
      {id:245, name:"Katha -  333 ", image:katha_23, details:""},
      {id:245, name:"Katha -  334 ", image:katha_24, details:""},
      {id:245, name:"Katha -  335 ", image:katha_25, details:""},
      {id:245, name:"Katha -  336 ", image:katha_26, details:""},


      {id:245, name:"Katha -  337 ", image:katha_27, details:""},
      {id:245, name:"Katha -  338 ", image:katha_28, details:""},
      {id:245, name:"Katha -  339 ", image:katha_29, details:""},
      {id:245, name:"Katha -  340 ", image:katha_30, details:""},
      {id:245, name:"Katha -  341 ", image:katha_31, details:""},
      {id:245, name:"Katha -  342 ", image:katha_32, details:""},
      {id:245, name:"Katha -  343 ", image:katha_33, details:""},
      {id:245, name:"Katha -  344 ", image:katha_34, details:""},
      {id:245, name:"Katha -  345 ", image:katha_35, details:""},
      {id:245, name:"Katha -  346 ", image:katha_36, details:""},
      {id:245, name:"Katha -  347 ", image:katha_37, details:""},
      {id:245, name:"Katha -  348 ", image:katha_38, details:""},
      {id:245, name:"Katha -  349 ", image:katha_39, details:""},
      {id:245, name:"Katha -  350 ", image:katha_40, details:""},
      {id:245, name:"Katha -  351 ", image:katha_41, details:""},
      {id:245, name:"Katha -  352 ", image:katha_42, details:""},
      {id:245, name:"Katha -  353 ", image:katha_43, details:""},
      {id:245, name:"Katha -  354 ", image:katha_44, details:""},
      {id:245, name:"Katha -  355 ", image:katha_45, details:""},
      {id:245, name:"Katha -  356 ", image:katha_46, details:""},
      {id:245, name:"Katha -  357 ", image:katha_47, details:""},


  ];
  
  export default data_photos;
  